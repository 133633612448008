<template>
    <div>
        <div class="page-content-box">			
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >                      
                    <el-table-column prop="status" label="状态" width="100"></el-table-column>
                    <el-table-column prop="position" label="宣传位" width="200"></el-table-column>
                    <el-table-column prop="showTimes" label="展示时间" min-width="180">
                        <template slot-scope="scope">
                            <div v-for="item in scope.row.showTimes" :key="item.firstTime.startTime">
                                <p v-if="item.firstTime">起:{{item.firstTime.startTime}}</p>
                                <p v-if="item.firstTime">止:{{item.firstTime.endTime}}</p>
                            </div>
                            <div v-for="item in scope.row.showTimes" :key="item.secondTime.startTime">
                                <p v-if="item.secondTime">起:{{item.secondTime.startTime}}</p>
                                <p v-if="item.secondTime">止:{{item.secondTime.endTime}}</p>
                            </div>
                            <div v-for="item in scope.row.showTimes" :key="item.thirdTime.startTime">
                                <p v-if="item.thirdTime">起:{{item.thirdTime.startTime}}</p>
                                <p v-if="item.thirdTime">止:{{item.thirdTime.endTime}}</p>
                            </div>
                        
                        </template>
                    </el-table-column>
                    <el-table-column prop="frequency" label="展示概率"></el-table-column>
                    <el-table-column prop="link" label="跳转链接" width="400">
                        <el-link target="_blank" :href="scope.row.link"  slot-scope="scope">{{scope.row.link}} </el-link>
                    </el-table-column>                    
                </el-table>
            </div>
            <div  class="page-pagination-box clearfix MT20">               
                <el-pagination
                    v-if="data"                   
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
            
        </div>
        
    </div>
</template>

<script>

export default {
    components:{},
    data(){
        return{
            data: {},
            dataList: [],
            params:{
                pageNo: 1,
                pageSize: 15,				
            },
            listLoading: true,			
			detailVisible: false,			
			detailId: null,
			addVisible:false,
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/schedule/resource/list",
                method: "POST",
                params: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                        console.log(this.dataList)
						
						
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
	   
        
    }
}
</script>